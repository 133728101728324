angular.module('gantt.table.templates', []).run(['$templateCache', function($templateCache) {
    $templateCache.put('plugins/table/sideContentTable.tmpl.html',
        '<div class="gantt-side-content-table" ng-controller="TableController">\n' +
        '    <table ng-show="gantt.columnsManager.columns.length > 0 && gantt.columnsManager.headers.length > 0" class="gantt-table">\n' +
        '        <thead>\n' +
        '            <tr class="gantt-table-row" ng-repeat="header in gantt.columnsManager.headers">\n' +
        '                <th class="gantt-table-column" ng-repeat="column in pluginScope.columns">\n' +
        '                    <div class="gantt-row-height gantt-table-cell" ng-class="{\'gantt-labels-header-row\': $parent.$last, \'gantt-labels-header-row-last\': $parent.$last}"><span>{{$parent.$last ? getHeader(this, column) : ""}}</span></div>\n' +
        '                </th>\n' +
        '            </tr>\n' +
        '        </thead>\n' +
        '        <tbody gantt-vertical-scroll-receiver>\n' +
        '            <tr class="gantt-table-row" ng-repeat="row in gantt.rowsManager.visibleRows track by row.model.id">\n' +
        '                <td class="gantt-table-column" ng-repeat="column in pluginScope.columns">\n' +
        '                    <div gantt-row-label\n' +
        '                         class="gantt-row-label gantt-row-height"\n' +
        '                         ng-class="row.model.classes"\n' +
        '                         ng-style="{\'height\': row.model.height}">\n' +
        '                        <span>{{getValue($parent, column)}}</span>\n' +
        '                    </div>\n' +
        '                </td>\n' +
        '            </tr>\n' +
        '        </tbody>\n' +
        '    </table>\n' +
        '</div>\n' +
        '');
}]);
